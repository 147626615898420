.collapseHeader {
  /* background-color: #071327; */
  background-color: #3f9f42;
  padding: 0.5em 0.5em;
  font-size: 1.5rem;
  cursor: pointer;
  margin-bottom: 0.5em;
  border-radius: 10px;
  color: white;
  transition: background-color 0.2s ease-out;
}
.collapseHeader:hover {
  /* background-color: #3f9f42; */
  background-color: #071327;

  color: white;
}

.collapseBody {
  overflow: hidden;
  transition: max-Height 0.3s cubic-bezier(0.5, 0, 0.1, 1);
}

@media screen and (max-width: 700px) {
  .collapseHeader {
    font-size: 1rem;
  }
}
