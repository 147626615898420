.mahsool {
  background-color: #3f9f42;
  color: white;
}

.mahsool:hover {
  background-color: #071327;
}

.countries-table td,
th,
tr {
  /* border: 1px solid #171718 !important; */
  font-size: 0.8rem;
}

.countries-table .title {
  background-color: #071327;
  color: white;
}
.countries-table .title:hover {
  background-color: #3f9f42;
}

.no-cell td:nth-child(6),
td:nth-child(10) {
  /* color: red !important; */
  border-left: 2px solid black !important;
}

.separateByBorder {
  border-left: 2px solid black !important;
}

.bottomSeparateByBorder {
  /* border-bottom: 2px solid black !important; */
}

.bold-util {
  font-weight: 900 !important;
  background-color: #a0b3d340;
}

.unit {
  font-weight: bolder !important;
  color: 'black' !important;
  margin-bottom: 0;
}
