.inputContainer {
  /* border: 2px solid red; */
  padding: 10em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ourIcon {
  font-size: 3rem;
  color: red;
}

.spin {
  margin-top: 3em;
}

.SearchInput {
  background: rgba(255, 255, 255, 0.4);
  border: none;
  display: block;
  outline: none;
  width: 50em;
  height: 8em;
  margin: auto;
  padding: 2em;
  color: #333;
  -webkit-box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.5);
}
.ourCard {
  margin-top: 2.5em;
}
::-webkit-input-placeholder {
  color: #666;
}
:-moz-placeholder {
  color: #666;
}
::-moz-placeholder {
  color: #666;
}
:-ms-input-placeholder {
  color: #666;
}
