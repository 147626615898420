.mobile-footer {
  /* height: 5em; */
  width: 100%;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  justify-content: center;
  gap: 10px;
  padding: 0.6em 0.5em;
}
.mobile-footer button {
  border: none;
  padding: 0.7em 0;
  font-weight: 500;
  flex-grow: 1;
  width: 30%;
  /* width: max-content; */
  /* height: 65%; */
  border-radius: 5px;
  color: white;
  background-color: #3f9f42;
}

/* .close-icon {
  position: absolute;
  top: 0.1em;
  right: 1em;
  height: 1em;
  width: 1em;
} */
