table {
  border: 2px solid black !important;
}

td,
th {
  border: 2px solid #999 !important;
  text-align: center;
}

.imgContainer {
  position: relative;
}

.mainImg {
  width: 100%;
}

.ourimgs {
  display: block;
  width: 22rem;
  height: 19rem;
  float: left;
  margin-right: 5rem;
}

.subTitle {
  color: #3f9f42;
}

.mainHeader {
  font-size: 5rem !important;
  color: #3f9f42;
  margin-bottom: 0.5em !important;
}

.troqMokaf7a {
  font-size: 0.8rem;
  color: #3f9f42;
}

.publicchartsp123 {
  margin-right: 7.7em !important;
  margin-top: 3.5em !important;
  font-size: 0.8rem !important;
  color: #666666 !important;
}
.publicchartspie123 {
  margin-top: -1em !important;
  color: green !important;
}

@media screen and (max-width: 700px) {
  .mainHeader {
    font-size: 3rem !important;
  }
  .troqMokaf7a {
    font-size: 0.6rem;
  }
}
